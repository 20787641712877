/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Slider from 'react-slick';
import React from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    array, func, object, bool, string,
} from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useDispatch } from 'react-redux';

import Styles from './AltImageSliderStyles';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-script-url */

const NextArrow = (props) => {
    const {
        onClick, className, hover, trackSlides,
    } = props;
    const handleClick = (e) => {
        onClick(e);
        trackSlides();
    };
    return (
        <button type="button" aria-label="Next Arrow" className={`${className}`} onClick={handleClick}>
            {hover && <ChevronRightIcon className="material-icons text-icon" />}
        </button>
    );
};
NextArrow.propTypes = {
    onClick: func,
    className: string,
    hover: bool,
    trackSlides: func.isRequired,
};

NextArrow.defaultProps = {
    onClick: () => {},
    className: '',
    hover: false,
};

const PrevArrow = (props) => {
    const {
        onClick, className, hover, trackSlides,
    } = props;
    const handleClick = (e) => {
        onClick(e);
        trackSlides();
    };
    return (
        <button type="button" aria-label="Prev Arrow" className={`${className}`} onClick={handleClick}>
            {hover && <ChevronLeftIcon className="material-icons text-icon" />}
        </button>
    );
};

PrevArrow.propTypes = {
    onClick: func,
    className: string,
    hover: bool,
    trackSlides: func.isRequired,
};
PrevArrow.defaultProps = {
    onClick: () => {},
    className: '',
    hover: false,
};

const AltImageSlider = (props) => {
    const {
        images = [], path, mainImage, hover, settings, product_name, classes,
    } = props;

    const dispatch = useDispatch();
    const trackSlides = () => dispatch(trackEvent({
        eventName: 'feature_interaction', // Requird for GA4 feature_click
        eventCategory: 'Collection Page',
        eventAction: 'Click',
        eventLabel: 'Alt image viewed',
    }));

    const sliderSettings = {
        ...settings,
        nextArrow: <NextArrow hover={hover} trackSlides={trackSlides} />,
        prevArrow: <PrevArrow hover={hover} trackSlides={trackSlides} />,
    };

    return (
        <Slider
            {...sliderSettings} // eslint-disable-line react/jsx-props-no-spreading
            className={`alt-image-slider-container ${classes.alt_image_slider_container}`}
        >
            <div>
                <img src={`http:${path}${mainImage}.jpg?height=456&width=418&sharpen=a0.5,r1,t1&quality=80&auto=webp&optimize={medium}`} alt={product_name} />
            </div>
            {images.map((element) => (
                <div key={element}>
                    <img src={`http:${path}${element}x.jpg?height=456&width=418&sharpen=a0.5,r1,t1&quality=80&auto=webp&optimize={medium}`} alt={product_name} />
                </div>
            ))}
        </Slider>
    );
};

AltImageSlider.propTypes = {
    images: array.isRequired,
    settings: object.isRequired,
    hover: bool.isRequired,
    classes: object.isRequired,
    product_name: string.isRequired,
    path: string.isRequired,
    mainImage: string.isRequired,
};

const enhance = compose(withStyles(Styles));

export default enhance(AltImageSlider);
