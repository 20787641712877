/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object,
} from 'prop-types';
import { getOrderId } from '../../../../state/ducks/Checkout/ducks/Common/Common-Selectors';
import { setMilVetTransactionId, setWesaluteMemberId, setWesaluteMemberTier } from './helpers/utilities';

const styles = () => ({
    weSaluteCotainer: {
        cursor: 'pointer',
        textAlign: 'center',
    },
});

class WesaluteBrandConnections extends Component {
    componentWillUnmount = () => {
        const existingWeSaluteIdScript = document.getElementById('wesalutescript');
        if (existingWeSaluteIdScript) {
            existingWeSaluteIdScript.parentNode.removeChild(existingWeSaluteIdScript);
        }
    }

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            this.loadWeSaluteScript();
        }
    };

    loadWeSaluteScript = () => {
        const existingWeSaluteIdScript = document.getElementById('wesalutescript');
        if (!existingWeSaluteIdScript) {
            const script = document.createElement('script');
            script.src = '//connections.wesaluteapis.com/loader/bc.js';
            script.id = 'wesalutescript';
            document.head.appendChild(script);
            script.onload = () => {
                this.loadWeSaluteWidget();
            };
        }
    };

    loadWeSaluteWidget = () => {
        const { history } = this.props;
        try {
            if (typeof window !== 'undefined' && window.initBrandConnections) {
                window.initBrandConnections({
                    client_id: '16c94c2b-cccf-4be8-a09c-2bbda349917f',
                    size: 'medium',
                    connectText: 'Sign in',
                    disconnectText: 'Sign Out',
                    /**
                 * Callback function to handle user data/errors
                 *
                 * @param {object} data
                 * @param {string} error
                 */
                    callback: (data, error) => {
                        if (error) {
                            console.log('Error while brand connection', error);
                        } else if (data) {
                            setMilVetTransactionId(data?.access_token); // access_token whoes expiration time is 24 Hrs
                            setWesaluteMemberId(data?.profile?.member_id); // member_id whoes expiration time is 24 Hrs
                            setWesaluteMemberTier(data?.profile?.tier); // tier whoes expiration time is 24 Hrs

                            history.push('/c/all-plants');
                        }
                    },
                    // Called when user logs out
                    loggedOut: () => {
                        console.log('User logged out!');
                    },
                }, '#WeSaluteContainer');
            }
        } catch (e) {
            console.log('Wesalute brand connection setup issue!');
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <div id="WeSaluteContainer" className={classes.weSaluteCotainer} />
                <div id="result" />
            </>
        );
    }
}

WesaluteBrandConnections.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
};

const mapStateToProps = (state) => ({
    orderId: getOrderId(state),
});

const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps),
);

export default enhance(WesaluteBrandConnections);
